html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.on-air-icon {
  display: inline-block;
  margin: 0 auto;
  border-radius: 50%;
  background: red;
  width: 6px;
  height: 6px;
  animation: live 2s infinite;
  position: relative;
  top: -15px;

  .on-air-text {
    display: inline-block;
    text-align: center;
    color: red;
    font-family: arial;
    font-weight: bold;
    position: relative;
    font-size: 12px;
    top: -11px;
    left: 0px;
    width: 60px;
  }
}

.hide-scrollbar {
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@keyframes live {
  0%,
  100% {
    background: none;
  }
  50% {
    background: red;
  }
}

.transparent {
  background-color: transparent !important;
}

.ck-editor__editable {
  min-height: 150px;
}

.chakra-form-control {
  .react-datepicker {
    font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI',
      Roboto, 'Helvetica Neue', sans-serif;
    overflow: hidden;
  }

  .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
    right: 90px;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    height: 8px;
  }

  .react-datepicker__navigation--previous {
    border-right-color: #cbd5e0;

    &:hover {
      border-right-color: #a0aec0;
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: #cbd5e0;

    &:hover {
      border-left-color: #a0aec0;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__header {
    border-radius: 0;
    background: #f7fafc;
  }

  .react-datepicker,
  .react-datepicker__header,
  .react-datepicker__time-container {
    border-color: #e2e8f0;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: inherit;
    font-weight: 600;
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    margin: 0 1px 0 0;
    height: auto;
    padding: 7px 10px;

    &:hover {
      background: #edf2f7;
    }
  }

  .react-datepicker__day:hover {
    background: #edf2f7;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item--selected {
    background: #3182ce;
    font-weight: normal;

    &:hover {
      background: #2a69ac;
    }
  }
}

// overwrite the ck-content
.ck-content {
  * {
    line-height: 1.5;
  }

  h1,
  h2,
  h3,
  h4 {
    line-height: 2;
  }

  h2 {
    color: #000;
    font-size: 30px;
  }

  h3 {
    color: #000;
    font-size: 26px;
  }

  h4 {
    color: #000;
    font-size: 20px;
  }

  p,
  li {
    font-size: 16px;
    color: #666;
  }

  > ol,
  > ul {
    margin-left: 1em;
  }

  li li {
    margin-left: 1em;
  }

  a {
    color: #59b0f6;

    &:hover {
      color: #0c81df;
      text-decoration: underline;
    }
  }

  blockquote {
    font-style: italic;
    padding-left: calc(1em - 4px) !important;
    border-left: 4px solid #59b0f6 !important;
  }
}

.plyr {
  width: 100%;
  height: 100%;
}

/**
* Shows how you can use CSS to style your Element's container.
* These classes are added to your Stripe Element by default.
* You can override these classNames by using the options passed
* to the CardElement component.
* https://stripe.com/docs/js/elements_object/create_element?type=card#elements_create-options-classes
*/
.StripeElement {
  height: 40px;
  padding: 10px 12px;
  width: 100%;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}
.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}
.StripeElement--invalid {
  border-color: #fa755a;
}
.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}
